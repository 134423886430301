exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-awards-recognition-js": () => import("./../../../src/templates/awards-recognition.js" /* webpackChunkName: "component---src-templates-awards-recognition-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-bmi-calculator-js": () => import("./../../../src/templates/bmi-calculator.js" /* webpackChunkName: "component---src-templates-bmi-calculator-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-corporate-insurance-services-js": () => import("./../../../src/templates/corporate-insurance-services.js" /* webpackChunkName: "component---src-templates-corporate-insurance-services-js" */),
  "component---src-templates-diabetes-care-products-js": () => import("./../../../src/templates/diabetes-care-products.js" /* webpackChunkName: "component---src-templates-diabetes-care-products-js" */),
  "component---src-templates-directors-js": () => import("./../../../src/templates/directors.js" /* webpackChunkName: "component---src-templates-directors-js" */),
  "component---src-templates-doctors-js": () => import("./../../../src/templates/doctors.js" /* webpackChunkName: "component---src-templates-doctors-js" */),
  "component---src-templates-education-js": () => import("./../../../src/templates/education.js" /* webpackChunkName: "component---src-templates-education-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-growth-story-js": () => import("./../../../src/templates/growth-story.js" /* webpackChunkName: "component---src-templates-growth-story-js" */),
  "component---src-templates-homage-js": () => import("./../../../src/templates/homage.js" /* webpackChunkName: "component---src-templates-homage-js" */),
  "component---src-templates-home-care-js": () => import("./../../../src/templates/home-care.js" /* webpackChunkName: "component---src-templates-home-care-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-international-patients-js": () => import("./../../../src/templates/international-patients.js" /* webpackChunkName: "component---src-templates-international-patients-js" */),
  "component---src-templates-new-blog-js": () => import("./../../../src/templates/new-blog.js" /* webpackChunkName: "component---src-templates-new-blog-js" */),
  "component---src-templates-our-magazine-js": () => import("./../../../src/templates/our-magazine.js" /* webpackChunkName: "component---src-templates-our-magazine-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-precision-diabetes-js": () => import("./../../../src/templates/precision-diabetes.js" /* webpackChunkName: "component---src-templates-precision-diabetes-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-single-centre-js": () => import("./../../../src/templates/single-centre.js" /* webpackChunkName: "component---src-templates-single-centre-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/single-doctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-location-js": () => import("./../../../src/templates/single-location.js" /* webpackChunkName: "component---src-templates-single-location-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-surgical-services-js": () => import("./../../../src/templates/surgical-services.js" /* webpackChunkName: "component---src-templates-surgical-services-js" */),
  "component---src-templates-testimonials-js": () => import("./../../../src/templates/testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-js" */),
  "component---src-templates-thandav-js": () => import("./../../../src/templates/thandav.js" /* webpackChunkName: "component---src-templates-thandav-js" */)
}

